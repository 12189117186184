<template>
  <div>
    <div class="table-operator" style="margin-bottom: 15px">
      <a-button type="primary" icon="plus" @click="modalAddEditLoad()">添加</a-button>
    </div>

    <s-table
      ref="pageTable"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data="dataset"
      :pagination="pagination"
      :pageSize="10"
    >
      <a slot="name" slot-scope="text">{{ text }}</a>
      <span slot="operation" slot-scope="text, record">
        <a @click="modalAddEditLoad(record)">查看</a> |
        <a-popconfirm
          title="您确认要删除此条记录吗？"
          ok-text="确认"
          cancel-text="取消"
          @confirm="handleRemove(record)"
        >
          <a>删除</a>
        </a-popconfirm>
      </span>
    </s-table>

    <a-modal
      key="addEdit"
      title="新增/编辑"
      :width="600"
      :footer="null"
      :centered="true"
      :keyboard="false"
      :maskClosable="false"
      :bodyStyle="{ height: '300px' }"
      :visible="modalAddEditVisible"
      @cancel="modalAddEditCancel"
    >
      <a-form :form="modalAddEditForm" @submit="modalAddEditSubmit">
        <a-form-item :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }" style="text-align: center">
          <a-radio-group
            name="priceType"
            v-decorator="['priceType', modalAddEditFormRules.priceType]"
            @change="handlePriceTypeChange"
          >
            <a-radio value="21">使用一口价</a-radio>
            <a-radio value="31">自定义价格</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item
          label="名称"
          :labelCol="{ lg: { span: 5 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-input name="name" v-decorator="['name', modalAddEditFormRules.name]" />
        </a-form-item>

        <div v-show="fieldPriceType == '21'">
          <a-form-item
            label="一口价"
            :labelCol="{ lg: { span: 5 }, sm: { span: 6 } }"
            :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
          >
            <a-select
              name="relationPriceId"
              placeholder="请选择一口价"
              v-decorator="['relationPriceId', modalAddEditFormRules.relationPriceId]"
            >
              <a-select-option v-for="record in fixedPriceList" :key="record.id" :value="record.id">
                {{ record.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>

        <div v-show="fieldPriceType == '31'">
          <a-form-item
            label="重量单价（元）"
            :labelCol="{ lg: { span: 5 }, sm: { span: 6 } }"
            :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
          >
            <a-input-number
              name="unitPrice"
              :min="0"
              :step="0.01"
              v-decorator="['unitPrice', modalAddEditFormRules.unitPrice]"
            />
          </a-form-item>
        </div>

        <a-form-item style="text-align: right">
          <a-input name="id" type="hidden" style="display: none" v-decorator="['id', modalAddEditFormRules.id]" />
          <a-button style="margin-right: 15px" @click="modalAddEditCancel">取消</a-button>
          <a-button type="primary" htmlType="submit">提交</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { STable } from '@/components'
import {
  customerPriceList,
  customerPriceInsert,
  customerPriceUpdate,
  customerPriceRemove,
  fixedPriceList,
} from '@/api/quotation'

const customerType = 1
const columns = [
  {
    key: 'name',
    dataIndex: 'name',
    title: '名称',
    align: 'center',
  },
  {
    key: 'updateDate',
    dataIndex: 'updateDate',
    title: '更新时间',
    align: 'center',
  },
  {
    key: 'updateName',
    dataIndex: 'updateName',
    title: '操作人',
    align: 'center',
  },
  {
    key: 'operation',
    dataIndex: 'operation',
    title: '操作',
    align: 'center',
    scopedSlots: {
      customRender: 'operation',
    },
  },
]
const modalAddEditFormRules = {
  id: { initialValue: null },
  customerType: { initialValue: customerType },
  priceType: { initialValue: '21' },
  name: { rules: [{ required: true, message: '请输入名称' }] },
  relationPriceId: { rules: [{ required: true, message: '请选择一口价' }] },
  unitPrice: { rules: [{ required: true, message: '请输入重量单价' }] },
}
const modalAddEditFormField = {
  priceType21: ['id', 'customerType', 'priceType', 'name', 'relationPriceId'],
  priceType31: ['id', 'customerType', 'priceType', 'name', 'unitPrice'],
}

export default {
  name: 'TableList',
  components: {
    STable,
  },
  data() {
    return {
      columns: columns,
      dataset: (parameter) => {
        parameter.customerType = customerType
        return customerPriceList(Object.assign(parameter, this.queryParam))
          .then((res) => {
            if (0 == res.code) {
              return res.result
            }
          })
          .catch((e) => {
            console.info(e)
            this.$message.error('数据加载失败，请稍后重试')
          })
      },
      pagination: { pageSizeOptions: ['10', '20', '50'] },
      queryParam: {},
      modalAddEditVisible: false,
      modalAddEditForm: this.$form.createForm(this),
      modalAddEditFormRules: modalAddEditFormRules,
      fieldPriceType: '21',
      fixedPriceList: [],
    }
  },
  mounted() {
    this.pageLoad()
  },
  methods: {
    pageLoad() {
      let that = this
      fixedPriceList({})
        .then((res) => {
          if (0 == res.code) {
            that.fixedPriceList = res.result.result
          }
        })
        .catch((e) => {
          console.info(e)
        })
    },
    modalAddEditLoad(record = null) {
      this.modalAddEditForm.resetFields()
      this.modalAddEditVisible = true
      if (record) {
        this.$nextTick(() => {
          var setFieldsValue = {
            id: record.id,
            priceType: String(record.priceType),
            name: record.name,
            unitPrice: record.unitPrice,
          }
          if (record.relationPriceId) {
            setFieldsValue.relationPriceId = record.relationPriceId
          }
          this.modalAddEditForm.setFieldsValue(setFieldsValue)
          this.fieldPriceType = String(record.priceType)
        })
      }
    },
    modalAddEditCancel() {
      this.modalAddEditVisible = false
    },
    modalAddEditSubmit(e) {
      e.preventDefault()
      let that = this
      const priceTypeField = 'priceType' + this.fieldPriceType
      this.modalAddEditForm.validateFields(modalAddEditFormField[priceTypeField], (err, values) => {
        if (!err) {
          if (values.id) {
            that.handleUpdate(values)
          } else {
            that.handleInsert(values)
          }
        }
      })
    },
    handleInsert(parameter) {
      parameter.customerType = customerType
      customerPriceInsert(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('保存成功')
            this.modalAddEditVisible = false
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('保存失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('保存失败，请稍后重试')
        })
    },
    handleUpdate(parameter) {
      customerPriceUpdate(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('保存成功')
            this.modalAddEditVisible = false
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('保存失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('保存失败，请稍后重试')
        })
    },
    handleRemove(record) {
      customerPriceRemove(record)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('删除成功')
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('删除失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('删除失败，请稍后重试')
        })
    },
    handlePriceTypeChange(e) {
      this.fieldPriceType = e.target.value
    },
  },
}
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100%;
}
/deep/ .ant-table-tbody{
  background-color: #fff;
}
/deep/ .ant-form-item-label{
  text-align: left !important;
}
</style>